<template>
  <ControlButton
    v-if="button.visible"
    :id="`details_button_${code}`"
    :disabled="!button.enabled"
    :model-value="activeUserPage === code"
    :inverted="false"
    button-style="button-notification"
    :toggle="true"
    :pending="pending"
    :notification="notification"
    @click="clickAction"
  >
    {{ button.label }}
    <template #icon>
      <IconPzo
        :name="icon"
        class="text-primary-1 group-aria-pressed/button:fill-base-2 contrast:group-hover/button:fill-base-2 text-2xl"
      />
    </template>
  </ControlButton>
</template>
<script setup lang="ts">
import type {
  UserPanelButtonCode,
  UserPanelDataButton,
} from "@/types/user/panelData";
import type { PzoIcon } from "common-components-types/icons";

interface Props {
  code: UserPanelButtonCode;
  button: UserPanelDataButton;
  icon: PzoIcon;
  action?: () => void;
  subpagePath?: string;
  pending?: boolean;
  notification?: boolean;
  switchPanel?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  pending: false,
  notification: false,
  switchPanel: true,
  action: undefined,
  subpagePath: undefined,
});

const { setActivePanel, level, activePanel, activeUserPage } =
  await useUserPanel();

function clickAction() {
  if (props.switchPanel === true) {
    setActivePanel("page");
  }
  if (props.action) {
    return props.action();
  }
  return navigateTo({
    path: props.subpagePath,
    query: { level: level.value, activePanel: activePanel.value },
  });
}
</script>
