<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    v-if="userPanel && refreshData"
    class="flex flex-col rounded border border-neutral-7 shadow w-full p-2 text-sm gap-1"
  >
    <div class="font-medium">{{ userPanel.buttons.refresh.label }}</div>
    <table class="mb-2">
      <tbody>
        <tr v-if="refreshData.registrationDateString">
          <td>
            {{ userPanel.localization.applicationRefreshRegistration }}
          </td>
          <td v-if="refreshData.registrationDateString" class="font-medium">
            {{ refreshData.registrationDateString }}
          </td>
        </tr>
        <tr v-if="refreshData.expirationDateString">
          <td v-if="refreshData.expirationDateString">
            {{ userPanel.localization.applicationRefreshExpiration }}
          </td>
          <td v-if="refreshData.expirationDateString" class="font-medium">
            {{ refreshData.expirationDateString }}
          </td>
        </tr>
        <tr v-if="refreshData.refreshDateString">
          <td v-if="refreshData.refreshDateString">
            {{ userPanel.localization.applicationRefresh }}
          </td>
          <td v-if="refreshData.refreshDateString" class="font-medium">
            {{ refreshData.refreshDateString }}
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="refreshData.refreshDescription"
      v-html="refreshData.refreshDescription"
    ></div>
    <div
      v-if="refreshData.tooOldDescription"
      class="font-medium text-base text-supporting-13"
      v-html="refreshData.tooOldDescription"
    ></div>
    <div class="text-base">
      <ControlButton
        v-if="refreshData.needsRefreshment"
        id="application_refresh_button"
        button-style="button-normal"
        class="w-full"
        @click="refreshApplication"
      >
        {{ userPanel.localization.applicationRefreshLabel }}
      </ControlButton>
    </div>
  </div>
  <div v-else></div>
</template>
<script lang="ts" setup>
import type { StatusResult } from "common-components-nuxt-types/common/statusResult";

const statusResult = useState<StatusResult | null>("statusResult");

const {
  data: userPanel,
  refresh: refreshUserPanel,
  level,
} = await useUserPanel();

const refreshRq = ref<{
  id: number;
}>();
const { data: refreshResponse } = await useApi("/api/user/applicationRefresh", {
  body: refreshRq,
  method: "post",
  immediate: false,
});

watch(refreshResponse, async (newValue) => {
  if (newValue) {
    await refreshUserPanel();
    statusResult.value = newValue;
  }
});

const refreshData = computed(() => {
  if (userPanel.value) {
    return userPanel.value.applicationRefreshData;
  }
});

function refreshApplication() {
  if (level.value) {
    refreshRq.value = {
      id: level.value,
    };
  }
}
</script>
