<template>
  <DefaultLayout :with-border="false">
    <StatusResult />
    <div
      v-if="userPanel"
      class="flex h-full w-full grow flex-row gap-2 group bg-neutral-8 contrast:bg-c-secondary-0 sm:overflow-visible"
      :data-visible-panel="activePanel"
    >
      <div
        class="w-full xl:basis-1/3 shrink-0 hidden xl:flex data-active:flex data-active:flex-col xl:flex-col bg-base-2 sm:rounded sm:border sm:border-neutral-7 contrast:sm:border-base-1 sm:overflow-clip"
        :data-active="activePanel === 'navigation'"
      >
        <div class="overflow-auto px-1 sm:px-2">
          <TitlePanel>
            {{ userPanel.localization.title }}
          </TitlePanel>
          <InputFilteredSelect
            v-if="studentList && studentList.length > 1"
            id="user_panel_student_select"
            :list="studentList"
            :model-value="studentId"
            list-key="studentId"
            list-value="firstLastName"
            :no-label="true"
            :no-unselect-icon="true"
            @update:model-value="
              (studentId) => switchStudent(studentId as number)
            "
          />
          <InputFilteredSelect
            v-if="userPanel.studentExistingApplicationLevels.length > 1"
            id="user_panel_level_select"
            :model-value="level"
            :list="userPanel.studentExistingApplicationLevels"
            list-key="level"
            list-value="name"
            :no-label="true"
            :no-unselect-icon="true"
            @update:model-value="updateLevel"
          />
          <div class="flex flex-wrap gap-2">
            <UserPanelButton
              subpage-path="/user"
              :button="userPanel.buttons.details"
              code="details"
              icon="list-alt"
            />
            <UserPanelButton
              subpage-path="/user"
              :button="userPanel.buttons.results"
              code="details"
              icon="list-alt"
            />
            <UserPanelButton
              :action="goToApplicationForm"
              :button="userPanel.buttons.show"
              code="form"
              icon="quick-reference-all"
              :pending="applicationFormPending"
              :switch-panel="false"
            />
            <UserPanelButton
              :action="goToApplicationForm"
              :button="userPanel.buttons.edit"
              code="form"
              icon="edit-document"
              :pending="applicationFormPending"
              :notification="
                notifications?.applicationNotCompleted ||
                notifications?.applicationCriteriaNotFilled
              "
              :switch-panel="false"
            />
            <UserPanelButton
              subpage-path="/user/attachments"
              :button="userPanel.buttons.attachments"
              code="attachments"
              icon="attachment"
              :notification="notifications?.missingAttachment"
            />
            <UserPanelButton
              :button="userPanel.buttons.download"
              code="download"
              icon="file-save"
              :pending="downloadApplicationPending"
              :action="downloadApplicationOrShowConfirmDialog"
            />
            <UserPanelButton
              subpage-path="/user/applications"
              :button="userPanel.buttons.applications"
              code="applications"
              :icon="userPanel.options.pzEnabled ? 'signature' : 'file-save'"
            />
            <UserPanelButton
              subpage-path="/user/appeals"
              :button="userPanel.buttons.appeals"
              code="appeals"
              icon="chat"
              :notification="notifications?.unreadAppealResponses"
            />
            <UserPanelButton
              subpage-path="/user/qualificationCard"
              :button="userPanel.buttons.qualificationCard"
              code="qualificationCard"
              icon="file-save"
            />
            <UserPanelButton
              subpage-path="/user/tests"
              :button="userPanel.buttons.tests"
              code="tests"
              icon="calendar-month"
            />
            <UserPanelButton
              subpage-path="/user/history"
              :button="userPanel.buttons.history"
              code="history"
              icon="history"
            />
            <UserPanelButton
              subpage-path="/user/accountSetting"
              :button="userPanel.buttons.settings"
              code="settings"
              icon="settings"
            />
            <UserApplicationSign
              v-if="
                userPanel.motherSign && userPanel.buttons.motherSign.visible
              "
              id="mother"
              :title="userPanel.buttons.motherSign.label"
              :enabled="userPanel.buttons.motherSign.enabled"
              :sign="userPanel.motherSign"
              :localization="userPanel.localization"
              :options="userPanel.options"
            ></UserApplicationSign>
            <UserApplicationSign
              v-if="
                userPanel.fatherSign && userPanel.buttons.fatherSign.visible
              "
              id="father"
              :title="userPanel.buttons.fatherSign.label"
              :enabled="userPanel.buttons.fatherSign.enabled"
              :sign="userPanel.fatherSign"
              :localization="userPanel.localization"
              :options="userPanel.options"
            ></UserApplicationSign>
            <UserApplicationRefresh
              v-if="userPanel.buttons.refresh.visible"
            ></UserApplicationRefresh>
            <ControlButton
              v-if="userPanel.buttons.addLevel.visible"
              id="add_level_button_action"
              :disabled="!userPanel.buttons.addLevel.enabled"
              button-style="button-default"
              :pending="insertApplicationLevelPending"
              class="flex justify-center gap-1 w-full border border-neutral-7 text-base shadow"
              @click="insertApplicationLevel"
            >
              {{ userPanel.buttons.addLevel.label }}
              <template #icon>
                <IconPzo name="add" class="text-lg" />
              </template>
            </ControlButton>
          </div>
          <div class="flex flex-col gap-2 mt-2 mb-1">
            <ControlButton
              v-if="
                authenticationData &&
                authenticationData.options.registrationEnabled &&
                authenticationData.options.wkLoginEnabled &&
                isWkSession &&
                !isMMSession
              "
              id="register_new_student_button_action"
              button-style="button-default"
              class="flex justify-center gap-1 w-full border border-neutral-7 text-base shadow"
              @click="register"
            >
              {{ authenticationData.localization.registerNewStudent }}
              <template #icon>
                <IconPzo name="person-add" class="text-lg" />
              </template>
            </ControlButton>
            <ControlButton
              v-if="
                authenticationData &&
                authenticationData.options.localLoginEnabled &&
                isWkSession &&
                !isMMSession
              "
              id="link_student_button_action"
              button-style="button-default"
              class="flex justify-center gap-1 w-full border border-neutral-7 text-base shadow"
              @click="linkStudent"
            >
              {{ authenticationData.localization.linkStudent }}
              <template #icon>
                <IconPzo name="link" class="text-lg" />
              </template>
            </ControlButton>
            <StatusText
              v-if="
                authenticationData &&
                authenticationData.options.registrationEnabled &&
                authenticationData.options.mmLoginEnabled &&
                isMMSession
              "
              status="HIGHLIGHT"
              :with-icon="false"
            >
              {{ userPanel.localization.registerInMMText }}
            </StatusText>
          </div>
        </div>
      </div>
      <ModalDialog
        v-if="userPanel.buttons.download.visible"
        id="dialog_confirm_download_application"
        ref="dialogElement"
        v-model="showApplicationDownloadConfirmationDialog"
        :dialog-title="globalLocalization.attention"
        :focus-on-close="applicationDownloadButton"
        close-title=""
      >
        <span>
          {{
            userPanel.localization.downloadApplicationLockEditionWarningLabel
          }}
        </span>
        <template #buttons>
          <ControlButton
            id="download_button_action"
            button-style="panel-button-normal-sm"
            @click="downloadApplicationAndHideDialog"
          >
            {{ userPanel.buttons.download.label }}
          </ControlButton>
          <ControlButton
            id="cancel_download_application"
            :inverted="false"
            button-style="panel-button-normal-sm"
            @click="showApplicationDownloadConfirmationDialog = false"
          >
            {{ globalLocalization.cancel }}
          </ControlButton>
        </template>
      </ModalDialog>
      <div
        class="overflow-clip grow hidden data-active:flex xl:flex bg-base-2 sm:border sm:border-neutral-7 contrast:sm:border-base-1 sm:rounded"
        :data-active="activePanel === 'page'"
      >
        <NuxtPage class="w-full overflow-auto sm:px-2 px-1 pb-16 sm:pb-2" />
      </div>
    </div>
  </DefaultLayout>
</template>
<script setup lang="ts">
import type { StatusResult } from "common-components-nuxt-types/common/statusResult";

const { activeMenu } = await useMenu();
activeMenu.value = "userPage";
const globalLocalization = useGlobalLocalization();

onMounted(() => {
  // Force a reload if the user is not logged in and page is stored in bfcache.
  window.addEventListener("pageshow", (event) => {
    if (event.persisted && !isAuthenticated.value) {
      location.reload();
    }
  });
});

const {
  defaultLevel,
  isAuthenticated,
  studentList,
  switchStudent,
  studentId,
  isWkSession,
  isMMSession,
} = await useAuth();
const {
  data: userPanel,
  level,
  downloadApplication,
  downloadApplicationPending,
  activePanel,
  notifications,
} = await useUserPanel();
const { authenticationData } = await useAuthenticationData();

const applicationDownloadButton = ref<HTMLElement>();
const showApplicationDownloadConfirmationDialog = ref(false);

async function updateLevel(
  level:
    | string
    | number
    | boolean
    | string[]
    | number[]
    | boolean[]
    | undefined,
) {
  if (level !== undefined) {
    await navigateTo({
      query: {
        level: level.toString(),
      },
    });
  } else if (defaultLevel.value !== undefined) {
    await navigateTo({
      query: {
        level: defaultLevel.value.toString(),
      },
    });
  }
}

const statusResult = useState<StatusResult | null | undefined>("statusResult");
const applicationDataStore = useApplicationDataStore(FORM_STORE_ID);
const applicationEditRequest = ref<{ level?: number }>();
const {
  data: applicationEditResult,
  pending: applicationFormPending,
  refresh: applicationEditRefresh,
} = useApi("/api/user/applicationEdit", {
  immediate: false,
  body: applicationEditRequest,
  method: "post",
  watch: false,
});
applicationFormPending.value = false;

async function goToApplicationForm() {
  applicationEditRequest.value = {
    level: level.value ?? defaultLevel.value,
  };
  await applicationEditRefresh();
  if (isStatusResult(applicationEditResult.value)) {
    statusResult.value = applicationEditResult.value;
  } else if (applicationEditResult.value !== null) {
    applicationDataStore.initialize(applicationEditResult.value);
    await navigateTo("/form");
  }
}

const {
  data: insertApplicationLevelResult,
  refresh: insertApplicationLevel,
  pending: insertApplicationLevelPending,
} = useApi("/api/user/insertApplicationLevel", {
  method: "post",
  immediate: false,
});
insertApplicationLevelPending.value = false;

watch(insertApplicationLevelResult, async (newValue) => {
  if (isStatusResult(newValue)) {
    statusResult.value = newValue;
  } else if (newValue !== null) {
    applicationDataStore.initialize(newValue);
    await navigateTo("/form");
  }
});

function downloadApplicationOrShowConfirmDialog() {
  if (
    userPanel.value?.options.lockApplicationEditionAfterPrintPublicEnabled &&
    userPanel.value?.buttons.edit.visible
  ) {
    showApplicationDownloadConfirmationDialog.value = true;
  } else {
    downloadApplicationAndHideDialog();
  }
}

const isSigned = computed(() => {
  if (!userPanel.value) {
    return false;
  }

  return (
    userPanel.value.motherSign?.signed || userPanel.value.fatherSign?.signed
  );
});

function downloadApplicationAndHideDialog() {
  downloadApplication(
    userPanel.value?.statusData.applicationId,
    isSigned.value,
  );
  showApplicationDownloadConfirmationDialog.value = false;
}

function linkStudent() {
  return navigateTo("/user/linkStudent");
}

function register() {
  return navigateTo("/register");
}
</script>
