<template>
  <div
    v-if="userPanel"
    class="flex flex-col rounded border border-neutral-7 contrast:border-base-1 shadow sm:flex-row sm:items-center w-full"
  >
    <div class="flex flex-col p-2 sm:grow sm:gap-2 w-full">
      <div class="text-xs font-medium text-neutral-4">{{ title }}</div>
      <div class="flex flex-col sm:flex-row gap-2">
        <div class="text-lg flex items-center justify-center w-full">
          {{ fullName }}
        </div>
        <div
          v-if="sign.signedData"
          class="leading-3 flex flex-col justify-center"
        >
          <div class="text-xs font-medium text-neutral-2">
            {{ userPanel.localization.applicationSignedLabel }}
          </div>
          <div class="text-xs tracking-tight">
            {{ sign.signedData }}
          </div>
        </div>
        <div
          v-if="enabled && sign.canSign"
          class="w-full sm:flex sm:justify-end items-end"
        >
          <ControlButton
            :id="`sign_button_${id}`"
            button-style="button-normal"
            class="w-full sm:basis-40"
            :disabled="isAnySignPending()"
            :pending="isSignPending(sign.signerNo)"
            @click="signApplication(sign.signerNo)"
          >
            {{ userPanel.localization.applicationSignLabel }}
          </ControlButton>
        </div>
      </div>
    </div>
    <div
      v-if="enabled && sign.canRemoveSign"
      class="p-2 pt-0 sm:ml-2 sm:self-stretch sm:p-0"
    >
      <ControlButton
        :id="`sign_remove_button_${id}`"
        class="m-0 h-full rounded-l-none px-4 focus-visible:-outline-offset-4 hidden sm:flex"
        :inverted="false"
        button-style="button-compact"
        :aria-label="userPanel.localization.applicationSignRemoveLabel"
        @click="removeSign(sign.signerNo)"
      >
        <template #icon>
          <IconPzo name="close" class="hidden sm:block" />
        </template>
      </ControlButton>
      <ControlButton
        :id="`sign_remove_button_mobile_${id}`"
        class="w-full sm:hidden"
        :inverted="false"
        button-style="button-normal"
        :aria-label="userPanel.localization.applicationSignRemoveLabel"
        @click="removeSign(sign.signerNo)"
      >
        {{ userPanel.localization.applicationSignRemoveLabel }}
      </ControlButton>
    </div>
  </div>
  <div v-else></div>
</template>
<script lang="ts" setup>
import type { UserApplicationSignData } from "@/types/user/signData";

interface Props {
  title: string;
  sign: UserApplicationSignData;
  enabled: boolean;
  id: string;
}

const props = defineProps<Props>();

const { data: userPanel, refresh: refreshUserPanel } = await useUserPanel();

const {
  signApplication,
  removeSign,
  isPending: isSignPending,
  isAnyPending: isAnySignPending,
} = await useApplicationSign(refreshUserPanel);

const fullName = computed(() => {
  return props.sign.firstName + " " + props.sign.lastName;
});
</script>
